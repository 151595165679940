/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, NativeSelect, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import UserService from "../../../services/User.Service";
import PermisoService from "../../../services/Permiso.service";
import S3Service from "../../../services/S3.service";

const TablaPermisos = () => {
  const { id } = useParams();
  const [usuario, setUsuario] = useState(null);
  const [imagen, setImagen] = useState("");
  const [permisosList, setPermisosList] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUsuarios();
  }, [update]);

  useEffect(() => {
    getPermisos();
  }, [page, limit]);

  const onUpdate = () => {
    setUpdate(!update);
  };
  const getUsuarios = async () => {
    try {
      const data = await UserService.getById(id);

      setUsuario(data);
      setPermisosList(data.permisos);

      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        console.log(imagen);
        setImagen(imagen.result);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getPermisos = async () => {
    setLoading(true);

    try {
      const data = await PermisoService.getAll();
      console.log(data);
      setPermisos(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      usuario.permiso = permisosList;
      const data = await UserService.update(usuario);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePermsio = (newEstado) => {
    let oldPermisos = permisosList.map((p) => {
      if (p.nombre === newEstado.nombre) {
        return newEstado;
      }
      return p;
    });
    console.log(oldPermisos);
    setPermisosList(oldPermisos);
  };

  const handleMetodoPago = async (newEstado) => {
    console.log(newEstado.target.value);
    console.log(newEstado.target.name);
    try {
      let dataPermiso = {
        nombre: newEstado.target.name,
        estado: newEstado.target.value,
        usuario: id,
      };
      const result = await PermisoService.update(dataPermiso);
      toast.info("Cambio guardado");
      // onUpdate();
      let normasStatus = permisosList.map((pl) => {
        if (pl.permiso?.nombre !== newEstado.target.name) {
          return pl;
        } else {
          pl.norma = dataPermiso.estado;
          return pl;
        }
      });

      // setPermisosList(normasStatus);
    } catch (error) {
      console.log(error);
      toast.error("No se ha asignado el permiso");
    }
  };

  const revisarPermiso = (norma) => {
    let normaStatus = permisosList.filter((pl) => pl.permiso?.id === norma);
    if (normaStatus[0]) {
      return normaStatus[0].norma;
    }
    return null;
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* main */}
          <main id="middle" className="flex-fill mx-auto">
            {/* <!-- PAGE TITLE --> */}
            <header>
              <h1 class="h4">Cuenta detalles</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb small">
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:void(0);"
                      onClick={() => navigate("/usuario")}
                    >
                      Usuario
                    </a>
                  </li>
                  <li
                    class="breadcrumb-item text-muted active"
                    aria-current="page"
                  >
                    Permisos
                  </li>
                </ol>
              </nav>
            </header>

            {/* <!-- profile overview --> */}
            <div class="section p-xl-4">
              <div class="d-flex">
                {/* <!-- image (desktop only) --> */}
                <div
                  class="me-4 d-none d-md-block"
                  style={{
                    height: "210px",
                    width: "140px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <img
                    height="210"
                    src={imagen}
                    alt="Foto"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>

                <div class="w-100 position-relative">

                  <h5>
                    {/* <!-- name --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                        fill="#00A3FF"
                      ></path>
                      <path
                        class="permanent"
                        d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                        fill="white"
                      ></path>
                    </svg>
                    <span class="fw-bold">{usuario?.nombre}</span>
                  </h5>

                  {/* <!-- attributes --> */}
                  <div class="d-lg-flex small mb-2">
                    <a
                      href="javascript:void(0)"
                      class="link-muted d-flex align-items-center me-3 mb-1"
                    >
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          opacity="0.3"
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        ></path>
                      </svg>
                      <span class="ms-2">{usuario?.tipo}</span>
                    </a>
                    {/* <a
                      href="javascript:void(0)"
                      class="link-muted d-flex align-items-center me-3 mb-1"
                    >
                      <svg
                        width="18px"
                        height="18px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="currentColor"
                          opacity="0.3"
                          d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                        ></path>
                      </svg>
                      <span class="ms-2">Mostrador</span>
                    </a> */}
                  </div>

                  {/* <!-- skill boxes --> */}
                  {/* <div class="d-xl-flex"> */}
                  {/* <!-- senior --> */}
                  {/* <div class="border border-dashed p-3 rounded w-100 max-w-300 me-2 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="w-100 me-4 pe-3 border-end">
                          <h4 class="mb-0">Inicio</h4>
                          <p class="mb-0 text-muted">5+ years</p>
                        </div>

                        <div class="w-100 me-4">
                          <h4 class="mb-0">Tickets</h4>
                          <p class="mb-0 text-muted">140+</p>
                        </div>
                      </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>

            {/* <!-- notifications --> */}
            <div class="section p-xl-4">
              <h3 class="h5 mb-5">Permisos</h3>

              {permisos.map((permiso, index) => (
                <>
                  <div key={index} class="d-flex justify-content-between">
                    <span class="fw-medium">
                      {permiso.nombre}
                      <span class="d-block smaller text-muted">
                        {permiso.descripcion}
                      </span>
                    </span>
                    <label class="d-flex align-items-center">
                      <select
                        class="form-select shadow-none"
                        id="shipping__country"
                        aria-label="Country"
                        value={revisarPermiso(permiso.id)}
                        onChange={handleMetodoPago}
                        name={permiso.nombre}
                      >
                        <option disabled="disabled" selected="selected">
                          Selecciona una
                        </option>
                        <option value="Ver">VER</option>
                        <option value="Ver,Editar">VER Y EDITAR</option>
                        <option value="Ocultar">OCULTAR</option>
                      </select>
                    </label>
                  </div>

                  <div class="border border-dashed border-bottom-0 my-3">
                    {/* <!-- divider --> */}
                  </div>
                </>
              ))}

              <div class="text-end">
                {/* <button type="submit" class="btn btn-primary" onClick={save}>
                  Guardar cambios
                </button> */}
              </div>
            </div>
          </main>
          {/* /main */}
        </>
      )}
    </>
  );
};

export default TablaPermisos;
